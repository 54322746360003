import React, { useState, useRef, useEffect } from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

import "./style.scss"
import { labels, headingLabels } from "./mockedData"
import {
  LensTypeFilters,
  BrandFilters,
} from "../FilterTypes"
import { useWindowWidth } from "../../services/hooks"

const LensesFilter = ({ productsLength, filter, setFilter, type }) => {
  const [active, setActive] = useState(false)
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()
  const filtersRef = useRef(null)

  useEffect(() => {
      setIsMobile(windowWidth < 578)
  }, [windowWidth])

  const appliedFiltersNumber = Object.entries(filter).reduce(
    (result, [key, values]) => {
      return (result += values.length)
    },
    0
  )

  const handleFilterBtnClick = () => {
    if (active) {
      filtersRef.current.removeAttribute("style")
      setTimeout(() => {
        setActive(prev => !prev)
      }, 500)
      return
    }
    if (isMobile) scrollTo(".glasses-filter")
    setActive(prev => !prev)
  }

  return (
    <div className="lenses-filter" data-type={type}>
      <div className="filter-heading">
        <button
          className={`filter-button ${active ? "active" : ""}`.trim()}
          onClick={() => handleFilterBtnClick()}
        >
          <span className="text h8 neutral">{`Filters ${
            appliedFiltersNumber ? `(${appliedFiltersNumber})` : ""
          }`}</span>
          <span className="switcher text h6 neutral">{active ? "—" : "+"}</span>
        </button>
        <span className="showing-items text neutral">
          Showing {productsLength} Items
        </span>
      </div>
      <FilterMenu
        active={active}
        filter={filter}
        setFilter={setFilter}
        type={type}
        filtersRef={filtersRef}
      />
      <AppliedFilters
        active={!active && appliedFiltersNumber}
        filter={filter}
        setFilter={setFilter}
      />
    </div>
  )
}

const FilterMenu = ({ active, filter, setFilter, type, filtersRef }) => {
  const handleFilter = (key, item) => {
    let newFilter = [...filter[key]]

    if (newFilter?.some(value => item === value)) {
      newFilter = newFilter?.filter(value => item !== value)
    } else {
      newFilter?.push(item)
    }

    setFilter({ ...filter, [key]: newFilter })
  }

  const tabsList = {
    brand: (
      <BrandFilters
        filter={filter.brand}
        setFilter={e => handleFilter("brand", e)}
        type={type}
      />
    ),
    lensType: (
      <LensTypeFilters
        filter={filter.lensType}
        setFilter={e => handleFilter("lensType", e)}
        type={type}
      />
    ),
  }

  const [currentTab, setCurrentTab] = useState(Object.keys(tabsList)[0])

  useEffect(() => {
    if (active) {
      filtersRef.current.setAttribute("style", "transform: translate(0, 0);")
    }
  }, [active])

  if (!active) return null

  return (
    <div className="filter-menu" ref={filtersRef} >
      <div className="filter-nav">
        {Object.keys(tabsList).map((key, index) => (
          <a
            className={`nav-item text h8 ${
              currentTab === key ? "bulky active" : "neutral"
            }`.trim()}
            key={index}
            onClick={(e) => {
              e.preventDefault()
              setCurrentTab(key)
            }}
          >
            {headingLabels[key]}
          </a>
        ))}
      </div>
      {tabsList[currentTab]}
    </div>
  )
}

const AppliedFilters = ({ active, filter, setFilter }) => {
  if (!active) return null

  const clearAll = () => {
    const newFilter = Object.keys(filter).reduce(
      (result, key) => ({ ...result, [key]: [] }),
      {}
    )

    setFilter(newFilter)
  }

  const handleRemove = (key, value) => {
    const newFilter = filter[key].filter(item => item !== value)

    setFilter(prev => ({ ...prev, [key]: newFilter }))
  }

  return (
    <div className="applied-filters">
      <p className="heading text h9 neue">Applied filters:</p>
      <div className="filters-list">
        <a className="applied-filter main text h9 neue" onClick={(e) => { e.preventDefault(); clearAll() }}>
          Clear All
        </a>
        {React.Children.toArray(
          Object.entries(filter).map(([key, values]) =>
            values.map(value => (
              <a className="applied-filter">
                <span className="text h9 neue white">{labels[value] || value}</span>
                <button
                  className="remove text h9 neue"
                  onClick={() => handleRemove(key, value)}
                >
                  ×
                </button>
              </a>
            ))
          )
        )}
      </div>
    </div>
  )
}

export default LensesFilter
