export const labels = {
  black: "Black",
  blue: "Blue",
  gray: "Gray",
  gold: "Gold",
  green: "Green",
  crystal: "Crystal",
  "multi-color": "Multi-color",
  pink: "Pink",
  red: "Red",
  tortoise: "Tortoise",
  square: "Square",
  round: "Round",
  oval: "Oval",
  heart: "Heart",
  diamond: "Diamond",
  aviator: "Aviator",
  rectangle: "Rectangular",
  cateye: "Cateye",
  narrow: "Narrow",
  wide: "Wide",
  medium: "Medium",
  male: "Male",
  female: "Female",
  unisex: "Unisex",
  kid: "Kids",
  kids: "Kids",
  astigmatism: "Contacts for Astigmatism",
  daily: "Daily Disposable",
  weekly: "Weekly Disposable",
  monthly: "Monthly Disposable",
}

export const headingLabels = {
  price: "Price",
  width: "Face Shape",
  shape: "Frame Shape",
  material: "Material",
  color: "Color",
  brand: "Brand",
  lensType: "Lens Type"
}
