import React from "react"
import { filters } from "./mockedData"
import WebpImage from "../WebpImage"

import "./style.scss"

const isActive = (value, filter) => filter?.some(item => item === value)

export const WidthFilters = ({ filter, setFilter, type }) => (
  <div className="width-filters centered">
    {filters.width.list.map(({ label, value }, index) => {
      const active = isActive(value, filter)
      const image = `assets/FilterTypes/width/${type}-${label}.png`

      return (
        <a
          className={`filter-item ${active ? "active" : ""}`.trim()}
          onClick={(e) => { e.preventDefault(); setFilter(value) }}
          key={index}
        >
          <WebpImage className={"background-image"} wrapperClassName={"background-image"} fileName={image} />
          <span className={`text h8 ${active ? "bulky" : "neutral"}`.trim()}>
            {label}
          </span>
        </a>
      )
    })}
  </div>
)

export const ShapeFilters = ({ filter, setFilter, type }) => (
  <div className="shape-filters">
    {filters.shape.list.map(({ label, value }, index) => {
      const active = isActive(value, filter)
      const image = `assets/FilterTypes/shape/${type}-${value}.png`

      return (
        <a
          className={`filter-item ${active ? "active" : ""}`.trim()}
          onClick={(e) => { e.preventDefault(); setFilter(value) }}
          key={index}
        >
          <WebpImage className={"background-image"} wrapperClassName={"background-image"} fileName={image} />
          <span className={`text h8 ${active ? "bulky" : "neutral"}`.trim()}>
            {label}
          </span>
        </a>
      )
    })}
  </div>
)

export const ColorFilters = ({ filter, setFilter }) => (
  <div className="color-filters">
    {filters.color.list.map(({ label, value }, index) => {
      const active = isActive(value, filter)
      const image = `assets/FilterTypes/colors/${value}.png`

      return (
        <a
          className={`filter-item ${active ? "active" : ""}`.trim()}
          onClick={(e) => { e.preventDefault(); setFilter(value) }}
          key={index}
        >
          <WebpImage fileName={image} alt="" />
          <span className={`text h8 ${active ? "bulky" : "neutral"}`.trim()}>
            {label}
          </span>
        </a>
      )
    })}
  </div>
)

export const BrandFilters = ({ filter, setFilter, type }) => (
  <div className="brand-filters centered">
    {filters.brand.list.map(({ label, value }, index) => {
      const active = isActive(value, filter)
      const image = `assets/FilterTypes/width/${type}-${label}.png`

      return (
        <a
          className={`filter-item ${active ? "active" : ""}`.trim()}
          onClick={(e) => { e.preventDefault(); setFilter(value) }}
          key={index}
        >
          <span className={`text h8 ${active ? "bulky" : "neutral"}`.trim()}>
            {label}
          </span>
        </a>
      )
    })}
  </div>
)

export const LensTypeFilters = ({ filter, setFilter, type }) => (
  <div className="lens-filters centered">
    {filters.lenseType.list.map(({ label, value }, index) => {
      const active = isActive(value, filter)
      const image = `assets/FilterTypes/width/${type}-${label}.png`

      return (
        <a
          className={`filter-item ${active ? "active" : ""}`.trim()}
          onClick={(e) => { e.preventDefault(); setFilter(value) }}
          key={index}
        >
          <span className={`text h8 ${active ? "bulky" : "neutral"}`.trim()}>
            {label}
          </span>
        </a>
      )
    })}
  </div>
)

export const PriceFilters = ({ filter, setFilter }) => {
  return <div className="price-filters"></div>
}

export const MaterialFilters = ({ filter, setFilter }) => {
  return <div className="material-filters"></div>
}
